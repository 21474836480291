import React from 'react'
import Layout from '../components/layout'
import { Link, useStaticQuery, graphql } from "gatsby"

import Head from '../components/head'
import Hero from '../components/hero'
import heroImage from "../images/hakkimizda_hero.png"
import altinGunu from "../images/altingunu.svg"
import soru from "../images/soru.svg"

import { RiArrowRightSLine } from 'react-icons/ri';

import '../styles/grid.scss'
import '../styles/index.scss'


const AboutPage = () => {
  const heroContent={
    title:"Hayallerini finanse edebilmeni istiyoruz.",
    description:"Birikim yapmak için kripto para uzmanı olup borsayı takip etmene gerek yok. Sevdiğin insanlarla, ailenle, arkadaşlarınla bir araya gelerek biriktirebilirsin. Hepimizin bildiği bu yöntem artık dijitalde. ",
    image:heroImage,
    trackLabel:"about"
  }

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata{
            email
        }
    }
    }
  `)

  return(
    <Layout>
      <Head title="Hakkımızda" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Çember'in kuruluş hikayesini bu sayfada bulabilirsin."  />
      <Hero title={heroContent.title}
      description={heroContent.description}
      image={heroContent.image}
      trackLabel={heroContent.trackLabel}/>  
  <div className="hikayemiz">
    <div className="row">  
      <h1>Nasıl Yola Çıktık?</h1>
          <div className="col-12">
            <p>Hemen şimdi birikim hesaplarına baktığında ne kadar birikim yaptığını görüyorsun? Diyelim ki biriktirebildiğin biraz para var. Peki birikimini ne sıklıkta artırabiliyorsun?</p>
            <p>Biz biriktiremiyoruz. <span role="img" area-label="Emoji"></span>🤷‍♂️🤷👀</p>
            <img src={altinGunu} alt="Altın Günü" />
            <p>
            Annelerimize nasıl biriktirebildiklerini sorduk. Altın günlerini biz hep kısır börek için yaptıklarını sanıyorduk. Asıl yaptıklarıysa altın günü sayesinde sıra kendilerine geldiğinde elde ettikleri toplu parayla kendilerine istedikleri şeyleri almalarıymış! Hatta halıcıyla anlaşıp her ay bir eve halı alındığını, tencereciyle anlaşıp düdüklü alındığını bile öğrendik. 
            </p>
            <p>
            Biz de bu yılların birikim yöntemini teknolojinin bize sağladığı imkanları da sonuna kadar kullanarak mutlu olarak para biriktirebileceğimiz Çember’i geliştirdik.
            </p>
            <p>
            Hayallerimizi kendi çevremiz veya tanımasak da aynı hayalleri paylaştığımız insanlarla bir araya gelerek çok daha kolay finanse edebileceğimizi biliyoruz. Bunu Çember'le hem güvenilir hem çok kolay hale getirmeye çalıştık.
            </p>
            <Link to="/blog/nasil-yola-ciktik"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Hikayemizin tamamını oku. </span> </Link>
          </div>
      
    </div>
   </div>

  <div className="row">
    <div className="container">
      <div className="col-12">
        <div className="sorukutu">
          <h1>Aklında sorular varsa bize ulaş!</h1>
          <Link to="/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> <span> Bize yaz </span> </Link>
          <img src={soru} alt="Soru Sor"/>
        </div>
      </div>
    </div>
  </div>

  <div className="row">
    <div className="container">
      <div className="col-12">
        <div className="isbirligi">
          <h1><span role="img" aria-label="Emoji">🤝</span>Bizimle işbirliği yapmak ister misiniz?<span role="img" aria-label="Emoji">🤝</span></h1>
          
          <p>Şu an bankalarla ve servis sağlayıcılarla görüşüyoruz. Herhangi bir işbirliği için bizimle iletişime geçebilirsiniz. </p>
          <Link to="/contact"> <RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/>  {data.site.siteMetadata.email}  </Link>
        </div>
      </div>
    </div>
  </div>
  
      


    </Layout>
  )
}

export default AboutPage